body {
  margin: 0;

 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* ////Navigation  */

/* ////Navigation  */
/* //cover */
.cover {
  background:url(./assets/walkator-klMii3cR9iI-unsplash.WebP);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin: 0 auto;
}
.icons-terminal{
  width: "20%";
  fill:black;


}
.clear-terminal{
  background-color: red;
  border-radius: 50%;
  margin-right: 6px;
}
.remove-terminal{
  background-color: #ffff00ba;
   border-radius: 50%; 
  margin-right: 6px;
}
.screen-terminal{
  background-color: green;
  border-radius: 50%;
  margin-right: 6px;
}
.link-button{
  display: flex;
  flex-direction: column;  
}
.voirPlus{
  width: 100px;
  height: 100px;
  color: silver;
  margin-bottom: 60px;
}
h1{    
  text-align: center;
  font-size: calc(1.375rem + 1.5vw);
  color: black;
  margin-top: 400px;
}
.cover button {
  color: black !important;
    cursor: pointer;
    width: 100px;
    margin-bottom: 50px;
    
    background-color: pink!important;
}
.link-button {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* ////Profil */
.paragraphe h1  {
  font-family: verdana;
  font-size: 40px;
  letter-spacing: 20px;
  opacity: 0.6;
  transition: 2s;
  text-shadow: 1px 1px 15px lightgrey;
  font-weight: lighter;
}
.paragraphe{
  background-color: white;
}
#profil container{
  display: flex;
  flex-direction: row;
}
.profil-content{
  display: flex;
  flex-direction: row; 
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
}
.profil-text{
  width: 500px;
    padding: 23px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}
/* .profil-content p {
  width: 500px;
} */
.paragraphe h1{
  margin-top: 20px;

}
.containere {
  margin: 0;
  padding: 120px;
  background: #fff;
  width: 100%;
  height: 150px;
}
/* //Parcours// */
#parcours h1, #competences h1, #portfolio h1 , #contact h1{
  margin-top: 0;
  font-family: verdana;
    font-size: 40px;
    letter-spacing: 20px;
    opacity: 0.6;
    transition: 2s;
    text-shadow: 1px 1px 15px lightgrey;
    font-weight: lighter;
}
#parcours{
  background-color: white;
}

/* //competences// */
#SkillBox {
  font-size: 20px;
  font-family: 'Indie Flower', cursive;
  width: 60%;
  height: auto;
  margin: 40px auto;
  background-color: #fff;
  padding: 10px;
  border-radius:20px;
  -o-border-radius:20px;
  -webkit-border-radius:20px;
  -ms-border-radius:20px;
  -moz-border-radius:20px;  
}
.SkillBox img {
  width: 20%;
  height: 10%;
  margin: auto 35%;
  padding: 10px;
}
.SkillBar {
  width: 90%;
  height: 36px;
  position: relative;
  background: rgba(17, 17, 17, .3);
  margin: 20px auto;
  border-radius: 20px;
}
#Skill-HTML {
  width: 80%;
  animation: Animate-HTML 4s;
  -webkit-animation: Animate-HTML 4s;
  -moz-animation: Animate-HTML 4s;
  -o-animation: Animate-HTML 4s;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-HTML {
  from {
  width: 10px;
}
to {
  width: 100%}
}@-webkit-keyframes Animate-HTML {
  from {
  width: 10px;
}
to {
  width: 100%}
}@-moz-keyframes Animate-HTML {
  from {
  width: 10px;
}
to {
  width: 100%}
}@-o-keyframes Animate-HTML {
  from {
  width: 10px;
}
to {
  width: 100%}
}#Skill-CSS {
  animation: Animate-CSS 5s;
  -webkit-animation: Animate-CSS 5s;
  -moz-animation: Animate-CSS 5s;
  -o-animation: Animate-CSS 5s;
  width: 80%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}

@keyframes Animate-CSS {
  from {
  width: 10px;
}
to {
  width: 70%}
}@-webkit-keyframes Animate-CSS {
  from {
  width: 10px;
}
to {
  width: 70%}
}@-moz-keyframes Animate-CSS {
  from {
  width: 10px;
}
to {
  width: 70%}
}@-o-keyframes Animate-CSS {
  from {
  width: 10px;
}
to {
  width: 70%}
}
#Skill-jQuery {
  animation: Animate-jQuery 5s;
  -webkit-animation: Animate-jQuery 5s;
  -moz-animation: Animate-jQuery 5s;
  -o-animation: Animate-jQuery 5s;
  width: 80%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-jQuery {
  from {
  width: 10px;
}
to {
  width: 40%}
}@-webkit-keyframes Animate-jQuery {
  from {
  width: 10px;
}
to {
  width: 40%}
}@-moz-keyframes Animate-jQuery {
  from {
  width: 10px;
}
to {
  width: 40%}
}@-o-keyframes Animate-jQuery {
  from {
  width: 10px;
}
to {
  width: 40%}
}#Skill-JS {
  animation: Animate-JS 4s;
  -webkit-animation: Animate-JS 4s;
  -moz-animation: Animate-JS 4s;
  -o-animation: Animate-JS 4s;
  width: 65%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-JS {
  from {
  width: 10px;
}
to {
  width: 65%}
}@-webkit-keyframes Animate-JS {
  from {
  width: 10px;
}
to {
  width: 65%}
}@-moz-keyframes Animate-JS {
  from {
  width: 10px;
}
to {
  width: 65%}
}@-o-keyframes Animate-JS {
  from {
  width: 10px;
}
to {
  width: 65%}
}#Skill-XML {
  animation: Animate-XML 4s;
  -webkit-animation: Animate-XML 4s;
  -moz-animation: Animate-XML 4s;
  -o-animation: Animate-XML 4s;
  width: 40%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-XML {
  from {
  width: 10px;
}
to {
  width: 40%}
}@-webkit-keyframes Animate-XML {
  from {
  width: 10px;
}
to {
  width: 40%}
}@-moz-keyframes Animate-XML {
  from {
  width: 10px;
}
to {
  width: 40%}
}@-o-keyframes Animate-XML {
  from {
  width: 10px;
}
to {
  width: 40%}
}#Skill-C {
  animation: Animate-C 4s;
  -webkit-animation: Animate-C 4s;
  -moz-animation: Animate-C 4s;
  -o-animation: Animate-C 4s;
  width: 80%;
  height: 36px;
  position: absolute;
  background-color:#373433ba;
  border-radius: 20px;
}
#Skill-BOOT {
  animation: Animate-C 4s;
  -webkit-animation: Animate-C 4s;
  -moz-animation: Animate-C 4s;
  -o-animation: Animate-C 4s;
  width: 70%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
.SkillBox p {
  text-align: center;
}
@keyframes Animate-C {
  from {
  width: 10px;
}
to {
  width: 30%}
}@-webkit-keyframes Animate-C {
  from {
  width: 10px;
}
to {
  width: 30%}
}@-moz-keyframes Animate-C {
  from {
  width: 10px;
}
to {
  width: 30%}
}@-o-keyframes Animate-C {
  from {
  width: 10px;
}
to {
  width: 30%}
}#Skill-JAVA {
  animation: Animate-JAVA 4s;
  -webkit-animation: Animate-JAVA 4s;
  -moz-animation: Animate-JAVA 4s;
  -o-animation: Animate-JAVA 4s;
  width: 39%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-JAVA {
  from {
  width: 10px;
}
to {
  width: 25%}
}@-webkit-keyframes Animate-JAVA {
  from {
  width: 10px;
}
to {
  width: 25%}
}@-moz-keyframes Animate-JAVA {
  from {
  width: 10px;
}
to {
  width: 25%}
}@-o-keyframes Animate-JAVA {
  from {
  width: 10px;
}
to {
  width: 25%}
}#Skill-PHP {
  animation: Animate-PHP 4s;
  -webkit-animation: Animate-PHP 4s;
  -moz-animation: Animate-PHP 4s;
  -o-animation: Animate-PHP 4s;
  width: 50%;
  height: 36px;
  position: absolute;
  background-color:#373433ba;
  border-radius: 20px;
}
@keyframes Animate-PHP {
  from {
  width: 10px;
}
to {
  width: 50%}
}@-webkit-keyframes Animate-PHP {
  from {
  width: 10px;
}
to {
  width: 50%}
}@-moz-keyframes Animate-PHP {
  from {
  width: 10px;
}
to {
  width: 50%}
}@-o-keyframes Animate-PHP {
  from {
  width: 10px;
}
to {
  width: 50%}
}
#Skill-SQL {
  animation: Animate-SQL 4s;
  -webkit-animation: Animate-SQL 4s;
  -moz-animation: Animate-SQL 4s;
  -o-animation: Animate-SQL 4s;
  width: 80%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-SQL {
  from {
  width: 10px;
}
to {
  width: 80%}
}@-webkit-keyframes Animate-SQL {
  from {
  width: 10px;
}
to {
  width: 80%}
}@-moz-keyframes Animate-SQL {
  from {
  width: 10px;
}
to {
  width: 80%}
}@-o-keyframes Animate-SQL {
  from {
  width: 10px;
}
to {
  width: 80%}
}#Skill-VBNET {
  animation: Animate-VBNET 4s;
  -webkit-animation: Animate-VBNET 4s;
  -moz-animation: Animate-VBNET 4s;
  -o-animation: Animate-VBNET 4s;
  width: 43%;
  height: 36px;
  position: absolute;
  background-color: #373433ba;
  border-radius: 20px;
}
@keyframes Animate-VBNET {
  from {
  width: 10px;
}
to {
  width: 35%}
}@-webkit-keyframes Animate-VBNET {
  from {
  width: 10px;
}
to {
  width: 35%}
}@-moz-keyframes Animate-VBNET {
  from {
  width: 10px;
}
to {
  width: 35%}
}@-o-keyframes Animate-VBNET {
  from {
  width: 10px;
}
to {
  width: 35%}
}.Skill-Area {
  z-index: 1;
  float: left;
  margin-top: 8px;
  margin-left: 15px;
  text-shadow: none;
  color: #fff;
  font-size: 13px;
}
.PercentText {
  z-index: 3;
  position: relative;
  padding-right: 15px;
  margin-top: 15px;
  float: right;
  text-shadow: none;
  color: #fff;
  font-size: 18px;
}
.cvPdf{
  display: none;
}
#competences .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#competences{
  background-color: white;
}
.SkillBox{
  width: 40%;
}
#competences img {
  width: 50%;
    height: 700px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin-top: 20px;
}
/* //projet// */
.col-6 a{
  text-decoration: none;
}
.card{
  cursor: pointer;
}
.card-border{
  object-fit: cover;
  width: 100%;
  height: 225px;
}
.card-text1{
  font-weight: 700;
}
.btn {
  padding: 0.6rem 1.3rem;
  background-color: #00000017!important;
  font-size: 0.95rem;
  color: black !important;
  line-height: 1;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn-outline {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

}
.comparaison .btn-outline{
  background-color: #f3976c !important;

}
.container-form h1 {
  margin-top: 0;
}
.container-form{
  position: relative;
    margin-top: -4rem;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f6f6; 
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
    z-index: 1;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border: none;
}
.container-form:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
/* //Projet2// */
.mission .btn-outline{
  background-color: rgb(255, 215, 215) !important;

}
/* .archi p , .archi li {
  width: 80%;
} */
.container-form img , .container-form p {
  width: 80%;
  object-fit: cover;
}
.container-form img{
  margin-top: 30px;
  margin-bottom: 30px;
}
/* //footerProjet */
.container-footer{
  display: flex;
    justify-content: space-between;
}
/* //typed */

.text-editor-wrap {
  display: block;
  margin: auto;
  max-width: 530px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 20px 70px;
  clear: both;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-bar {
  display: flex;
  padding: 5px 0;
  font-family: "Lucida Grande", sans-serif;
  font-size: 0.75em;
  text-shadow: rgba(255, 255, 255, 0.8) 0px 1px 0px;
  background-color: #ffff;
  background-image: -webkit-linear-gradient(to bottom, #e8e8e8, #bcbbbc);
  background-image: -moz-linear-gradient(to bottom, #e8e8e8, #bcbbbc);
  background-image: linear-gradient(to bottom, #e8e8e8, #bcbbbc);
  box-shadow: inset rgba(255, 255, 255, 0.7) 0px 1px 1px;
  border-bottom: #6a6a6a 1px solid;
}
.title{
  margin-left: 80px;
}
.icons-terminal{
  margin-left: 3px;
}
.text-body {
  height: 400px;
      width: 600px;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 10px;
  color: #f0f0f0 !important;
  text-shadow: #000 0px 1px 0px;
  font-family: "Consolas", "Courier New", "Courier";
  font-size: 1.45em;
  line-height: 1.40em;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.typed-cursor {
  opacity: 1;
  font-weight: 100;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  -ms-animation: blink 0.7s infinite;
  -o-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
/* ///contact */
#contact{
  margin-bottom: 50px;
}
#contact .container {
  display: flex;
  background-color: white;
}
#contact .col {
  font-size: 20px;
}
#contact .row{
display: flex;
}
#contact .profil-content{
  display: flex;
  flex-direction: row-reverse; 
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  width: 100%
}
/* //portfolio */
#portfolio{
  background-color: white;
}
.card{
  margin-top: 20px;
}
.tech-icon{
  margin-top: -7px;
    padding-bottom: 10px
}
.d-flex{
  font-size: 20px;
}
.col-6:hover .card , .col-6:hover .btn-outline{
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media (max-width:992px) {

.paragraphe h1 {
  font-family: verdana;
  font-size: 30px
}}
@media (max-width:870px) {
  
.paragraphe h1 ,#parcours h1, #competences h1, #portfolio h1 , #contact h1{
  
  font-size: 25px
}
.py-3 {
 
  padding-left: 8px!important;
}
}
@media (max-width:768px) {
  
  .paragraphe h1 ,#parcours h1, #competences h1, #portfolio h1 , #contact h1{
    
    letter-spacing: 4px;  
  }
  
  #competences .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .profil-content svg {
    display: none;
  }
  .profil-content {
    margin-top: 0px;
}
  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-around;
  }
  .col-6 {
    width: 94%!important}
    .text-body {
      height: auto;
      width: 400px;
      
    }
    .col-6 a {
      text-decoration: none!important;
    }
    .text-body {
    
      font-size: 14px;
  }
  .containere {
    
    padding: 67px;}
    /* ol, ul {
      padding-left: 1rem!important;
  } */
  .timeline > li > .timeline-panel {
 
    padding: 9px;}
    .timeline-title {
      font-size: 15px;
    }
    .timeline-body li{
font-size: 12px;
    }
    .timeline-body ul {
      padding-left: 5px;
    }
    h6{
      font-size: 13px;
    }
    .timeline > li > .timeline-panel {
      
      padding: 13px;}
      .SkillBox {
        width: 100%;
    }
    #competences img {
      display: none;
    }
    .cvPdf{
      display: block;
    }
    .cvPdf a {
      text-decoration: none; /* Pour supprimer le soulignement du lien */
      color: inherit; /* Pour hériter de la couleur du texte du parent */
      transition: background-color 0.3s ease;
    }
    
    .cvPdf a:hover {
      color: blue;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
    .timeline > li > .timeline-panel {
        padding: 11px!important;}
        footer{
          flex-direction: column
        }
        footer ul{
        display: flex;
        flex-direction: row!important;
        justify-content: center!important;
        }
}

@media (max-width:480px){
  .voirPlus{
  height: 80px;
  width: 80px;
    margin-bottom: 10px!important;
  }

  .text-body {
    height: auto;
    width: 310px;
}
.timeline-title {
  font-size: 10px;
}
.small, small ,h6 ,.Skill-Area{
  font-size: 10px!important;
}
.title {
  margin-left: 10px;
}
.paragraphe h1, #parcours h1, #competences h1, #portfolio h1, #contact h1 {
  font-size: 18px;
}

}