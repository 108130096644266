.navbar_header {
    width: 100%;
    position: fixed;
    display: flex;
    padding: 1em 0;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    background: linear-gradient(black 85%);
    box-shadow: 1em 1em 10em 0.1em ;
    align-items: center;
  }
  .navbar-brand{
    font-style: italic;
    cursor: pointer;
  }
  .logo-title{
    margin: 0 auto;
    color: white;
    font-weight: bold;
  }
  .navbar_header {
    background: linear-gradient(#000,85%,rgba(110,199,224,.804));
    box-shadow: 1em 1em 10em 0.1em rgba(110,199,224,.804);
    display: flex;
    padding: 1em 0;
    position: fixed;
    transition: all .3s ease-in-out;
    width: 100%;
    z-index: 2;
}
  
  .logo-container {
    margin: 0 auto;
  }
  
  .navbar_logo {
    cursor: pointer;
    width: 4vh;
    transform: scale(1);
    transition: all 1s ease-in-out;
  }
  
  .logo-container:hover .navbar_logo {
    transform: scale(1.2);
  }
  
  .navbar_links {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0 auto;
    align-items: center;
  }
  
  .navbar_item {
    color: white;
    padding: 0 2em 0 0.2em;
    font-weight: 700;
    cursor: pointer;
    position: relative;
  }
  
  .navbar_item:hover {
    color: white;
    z-index: 999;
  }
  
  .navbar_item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 90%;
    background-color: #6ec7e0;
    opacity: 0.7;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease-in-out;
    z-index: -1;
  }
  
  .navbar_item:hover::before {
    transform: scaleX(1);
  }
  
  .navbar_burger {
    width: 30px;
    height: 30px;
    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
  }
  
  .navbar_header--transparent {
    transition: all 0.3s ease-in-out;
    background: transparent;
    box-shadow: none;
  }
  
  @media screen and (max-width: 768px) {
    .navbar_header > .logo-container {
      margin: 0;
      padding: 0 3vw;
    }
    .logo-title {
        margin-left: 11px;
       
    }
    .navbar_links {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      right: -100vw;
      bottom: 0;
      height: 100vh;
      width: 0;
      padding: 2rem;
      visibility: hidden;
      background-color: black;
      transition: all 0.8s ease-in-out;
    }
  
    .navbar_link {
      display: block;
      padding: 1.2rem;
      font-size: 4vw;
    }
  
    .show_nav .navbar_links {
      right: 0;
      width: 100vw;
      visibility: visible;
    }
  
    .navbar_item::after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 4vw;
      height: 1px;
      background-color: #6ec7e0;
    }
  
    .navbar_item:last-child:after {
      display: none;
    }
  
    .navbar_burger {
      display: block;
      position: fixed;
      top: 1rem;
      right: 2rem;
    }
  
    .burger_bar,
    .burger_bar::after,
    .burger_bar::before {
      display: block;
      width: 30px;
      height: 2px;
      border-radius: 2px;
      position: relative;
      background-color: #fcf7f8;
      transition: all 0.5s ease-in-out;
    }
  
    .burger_bar::after,
    .burger_bar::before {
      content: '';
      position: absolute;
      left: 0;
    }
  
    .burger_bar::before {
      transform: translateY(-10px);
    }
  
    .burger_bar::after {
      transform: translateY(10px);
    }
  
    .burger_bar:hover {
      cursor: pointer;
    }
  
    .show_nav .burger_bar {
      width: 0;
      background: transparent;
    }
  
    .show_nav .burger_bar::before {
      transform: rotate(45deg);
    }
  
    .show_nav .burger_bar::after {
      transform: rotate(-45deg);
    }
  
    .navbar_item {
      transform: translateY(100vh);
    }
  
    .show_nav .navbar_item {
      transform: translateY(0);
    }
  
    .show_nav .navbar_item.slideInDown1 {
      transition: all 1s ease-in-out;
      padding-bottom: 30px
    }
  
    .show_nav .navbar_item.slideInDown2 {
      transition: all 1.1s ease-in-out;
      padding-bottom: 30px
    }
  
    .show_nav .navbar_item.slideInDown3 {
      transition: all 1.2s ease-in-out;
      padding-bottom: 30px
    }
  
    .show_nav .navbar_item.slideInDown4 {
      transition: all 1.3s ease-in-out;
      padding-bottom: 30px
    }
  
    .show_nav .navbar_item.slideInDown5 {
      transition: all 1.4s ease-in-out;
      padding-bottom: 30px
    }
  }